:root {
  --color-red: #ff1643;
  --color-green: #538a2d;
  --color-brown: #5c4037;
  --color-yellow: #e7ef9d;
}

.website {
  z-index: 3;
  // border: 4px solid black;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 2rem;
  background-color: white;
  header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: var(--color-green);
    color: #fff;
    padding: 1rem 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;

      @media (max-width: 567px) {
        padding: 0 0.5rem;
      }

      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
      }

      li {
        margin: 0 1rem;
        font-weight: bold;
        cursor: pointer;

        @media (max-width: 567px) {
          margin: 0 0.8rem;
        }

        &.active {
          font-weight: bold;
          color: var(--color-yellow);
        }
      }
    }
  }

  section.projects,
  section.about {
    height: fit-content;
    // margin-top: 1.2rem;
    // background-color: red;
  }

  section.projects {
    .project-box {
      // width: 100%;
      // border: 5px solid black;
      width: 80%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 1rem;

      @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 772px) {
        grid-template-columns: repeat(1, 1fr);
      }
      @media (max-width: 400px) {
        width: 100%;
        padding: 0 1rem;
      }
    }
  }

  // .projects,
  // .about,
  // .timeline-section {
  // margin-top: 4rem;
  // border: 2px solid blue;
  // padding: 2rem;
  // max-width: 1000px;
  // margin: 0 auto;
  // }

  .projects h2,
  .about h2,
  .timeline-section h2 {
    display: none;
    text-align: center;
    color: var(--color-red);
  }

  .project-card {
    background-color: var(--color-yellow);
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .project-card h3 {
    margin: 0 0 1rem 0;
  }

  .project-card p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .project-card a {
    width: fit-content;
    color: var(--color-green);
    // text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    svg {
      // border: 1px solid black;
      margin: 1rem 0 0 0.3rem;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  .project-card iframe {
    width: 100%;
    max-width: 360px;
    height: 250px;
    margin-top: 1rem;
    border-radius: 5px;
    border: none;
  }

  .projects {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3rem 0 0 0;
  }
  .about {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 3rem 0 0 0;
    .about-box {
      // border: 1px solid red;
      width: 50%;

      @media (max-width: 765px) {
        width: 90%;
      }
    }
    .month-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      .selector {
        .label {
          font-weight: 600;
        }
      }
    }
    .monthly-activity {
      margin-bottom: 2rem;

      h3 {
        position: sticky;
        top: 1.5rem;
        background-color: #fff;
        // border: 1px solid red;
        color: var(--color-red);
        border-bottom: 2px solid var(--color-red);
        padding-bottom: 0.5rem;
        padding-top: 1rem;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          background-color: var(--color-yellow);
          margin: 0.5rem 0;
          padding: 0.5rem;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  // .cv .cv-download {
  //   display: block;
  //   margin: 2rem auto;
  //   padding: 1rem 2rem;
  //   background-color: var(--color-red);
  //   color: #fff;
  //   text-decoration: none;
  //   text-align: center;
  //   font-weight: bold;
  //   border-radius: 5px;
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //   transition: background-color 0.3s ease;
  // }

  // .cv .cv-download:hover {
  //   background-color: var(--color-brown);
  // }
}
