.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // font-family: "Playfair Display";

  .linktree-section {
    position: absolute;
    width: 100vw;
    height: 100vh;
    // background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .main {
      width: 100%;
      .heading {
        .profile-img {
          height: 6rem;
          width: 6rem;
          border-radius: 50%;
          object-fit: contain;
          box-shadow: 1px 2px 3px rgba(168, 168, 168, 0.589);
        }

        .page-title {
          font-size: 1.1rem;
          font-weight: 500;
          font-family: "Sacramento";
        }

        .page-bioline {
          font-weight: 400;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .portfolio-section {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    z-index: 2;
    transition: transform 0.7s ease-in-out;

    &.visible {
      transform: translateY(0);
    }

    .portfolio-content {
      // border: 1px solid black;
      // padding: 3rem;
    }
  }
}
