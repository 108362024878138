#websiteLinks {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    transition: all 0.1s ease-out;

    a {
      text-decoration: none;
      color: black;
      background-color: rgba(177, 177, 177, 0.26);
      width: 100%;
      box-shadow: 0px 0px 3px rgba(168, 168, 168, 0.589);
      padding: 1rem;
      border-radius: 20px;
      font-size: 1rem;
      text-align: center;
    }
    &:hover {
      transform: scale(1.03);
    }
  }
}

@media (max-width: 768px) {
  #websiteLinks div {
    width: 75%;
    a {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 400px) {
  #websiteLinks div {
    width: 90%;
    a {
      font-size: 0.9rem;
    }
  }
}
