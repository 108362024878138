#socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
  margin-top: 1.5rem;

  .social-icon-fill {
    // height: 1.9rem;

    svg {
      font-size: 1.8rem;
      font-weight: 400;
      color: black;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: 768px) {
  #socialLinks {
    column-gap: 1.5rem;
  }
}

@media (max-width: 400px) {
  .social-icon-fill {
    font-size: 1.7rem;
  }
}
