.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .goback {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #ff1643;

    a {
      width: fit-content;
      color: #538a2d;
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      svg {
        margin-top: 0.1rem;
        margin-right: 0.3rem;
        animation: rotating 3s linear infinite;
      }

      &:hover {
        svg {
          color: #5c4037;
        }
      }
      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .loader {
    width: 80px;
    height: 40px;
    border-radius: 0 0 100px 100px;
    border: 5px solid #538a2d;
    border-top: 0;
    box-sizing: border-box;
    background: radial-gradient(
        farthest-side at top,
        #0000 calc(100% - 5px),
        #e7ef9d calc(100% - 4px)
      ),
      radial-gradient(2px 3px at 0 0, #5c4037 89%, #0000) 0 0 / 17px 12px,
      #ff1643;
    --c: radial-gradient(farthest-side, #000 94%, #0000);
    -webkit-mask: linear-gradient(#0000 0 0), var(--c) 12px -8px,
      var(--c) 29px -8px, var(--c) 45px -6px, var(--c) 22px -2px,
      var(--c) 34px 6px, var(--c) 21px 6px, linear-gradient(#000 0 0);
    mask: linear-gradient(#000 0 0), var(--c) 12px -8px, var(--c) 29px -8px,
      var(--c) 45px -6px, var(--c) 22px -2px, var(--c) 34px 6px,
      var(--c) 21px 6px, linear-gradient(#0000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude, add, add, add, add, add, add;
    -webkit-mask-repeat: no-repeat;
    animation: l8 3s infinite;
  }
  @keyframes l8 {
    0% {
      -webkit-mask-size: auto, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
    }
    15% {
      -webkit-mask-size: auto, 20px 20px, 0 0, 0 0, 0 0, 0 0, 0 0;
    }
    30% {
      -webkit-mask-size: auto, 20px 20px, 20px 20px, 0 0, 0 0, 0 0, 0 0;
    }
    45% {
      -webkit-mask-size: auto, 20px 20px, 20px 20px, 20px 20px, 0 0, 0 0, 0 0;
    }
    60% {
      -webkit-mask-size: auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px, 0 0,
        0 0;
    }
    75% {
      -webkit-mask-size: auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px,
        20px 20px, 0 0;
    }
    90%,
    100% {
      -webkit-mask-size: auto, 20px 20px, 20px 20px, 20px 20px, 20px 20px,
        20px 20px, 20px 20px;
    }
  }
}
